import React from 'react';

const About = () => {
    return (
        <div className='text-white w-full py-12' id='about'>
            <div className="flex flex-col md:flex-row items-center justify-center gap-4 md:gap-6 px-4 md:px-10 w-full">
                <div className='w-full max-w-4xl mx-auto'>
                    <div className='rounded-2xl shadow-xl p-4 md:p-8 text-center my-4 md:my-8 transition duration-500 ease-in-out transform hover:scale-105'>
                        <h3 className='text-3xl md:text-4xl lg:text-5xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-teal-400'>
                            Despre cursuri
                        </h3>
                        <p className='text-sm md:text-base lg:text-xl leading-relaxed md:leading-loose text-gray-300 mb-6'>
                            Bine ați venit la cursurile noastre de informatică, dedicate copiilor pasionați de tehnologie și curioși să descopere tainele lumii digitale! Aceste cursuri sunt concepute pentru a oferi copiilor oportunitatea de a se dezvolta într-un mediu prietenos, stimulativ și interactiv, la Colegiul Național "Emil Racoviță".
                        </p>
                        
                        <p className='text-sm md:text-base lg:text-xl leading-relaxed md:leading-loose text-gray-300 mb-6'>
                            Scopul nostru este să ajutăm copiii să își dezvolte gândirea logică și abilitățile de rezolvare a problemelor, prin explorarea unor concepte esențiale precum programarea, algoritmica și bazele informaticii. De asemenea, punem un accent deosebit pe creativitate și colaborare, oferindu-le participanților posibilitatea de a-și dezvolta abilitățile de lucru în echipă.
                        </p>

                        <p className='text-sm md:text-base lg:text-xl leading-relaxed md:leading-loose text-gray-300 mb-6'>
                            Cursurile noastre sunt structurate astfel încât să îmbine învățarea teoretică cu exerciții practice și proiecte interesante. De la noțiuni de bază, până la dezvoltarea de mici aplicații sau jocuri, copiii vor putea să își exprime ideile într-un mod inovator, învățând în același timp să își asume responsabilități și să colaboreze cu ceilalți colegi.
                        </p>

                        <ul className='list-disc list-inside text-left text-sm md:text-base lg:text-xl leading-relaxed md:leading-loose text-gray-300 mb-6'>
                            <li>Dezvoltăm creativitatea și încurajăm inovația prin activități practice captivante.</li>
                            <li>Copiii învață prin joc și colaborare, dezvoltând abilități esențiale pentru viitor.</li>
                            <li>Folosim metode de predare interactive, adaptate vârstei și nivelului fiecărui copil.</li>
                            <li>Cursurile includ provocări și proiecte care stimulează gândirea critică și rezolvarea problemelor.</li>
                            <li>Încurajăm lucrul în echipă și comunicarea eficientă, pentru ca fiecare copil să își dezvolte încrederea în sine și în propriile abilități.</li>
                        </ul>
                        
                        <p className='text-sm md:text-base lg:text-xl leading-relaxed md:leading-loose text-gray-300 mb-6'>
                            Profesorii noștri sunt specialiști în domeniul tehnologiei și educației, cu experiență atât în programare, cat și în mentorat. Ei sunt dedicați să creeze un mediu de învățare plăcut și sigur, unde fiecare copil să se simtă motivat să exploreze și să își depășească limitele.
                        </p>

                        <p className='text-sm md:text-base lg:text-xl leading-relaxed md:leading-loose text-gray-300 mb-6'>
                            Fiecare lecție este gândită pentru a fi o experiență unică, în care copiii se pot dezvolta la nivel personal și profesional. Prin metode de învățare moderne și tehnologie de ultimă generație, oferim copiilor toate resursele necesare pentru a face primii pași spre o carieră de succes în domeniul tehnologiei.
                        </p>

                        <p className='text-sm md:text-base lg:text-xl leading-relaxed md:leading-loose text-gray-300'>
                            Vă invităm să ne alăturați în această aventură digitală și să descoperim împreună potențialul fiecărui copil. Prin joacă, colaborare și tehnologie, le oferim oportunitatea de a deveni creatori ai viitorului!
                        </p>
                    </div>

             
                    
                </div>
            </div>
        </div>
    );
}

export default About;
