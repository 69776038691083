import React from "react";
import pozaeu from "../assets/eu1.png";
import pozasergiu from "../assets/sergiu.jpeg";


const Portfolio = () => {
    return (
        <div className='text-white w-full py-12' id='meet-the-team'>
            {/* Title Section */}
            <div className='text-center mb-12'>
                <h2 className='text-4xl md:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-teal-400'>
                    Descoperă Echipa
                </h2>
                
            </div>

            <div className="flex flex-col items-center justify-center gap-8 md:gap-12 px-4 md:px-10 w-full">
    {/* Section for You */}
    <div className='w-full max-w-xl mx-auto'>
        <div className='rounded-2xl shadow-xl p-4 md:p-8 text-center my-4 transition duration-500 ease-in-out transform hover:scale-105'>
            <img 
                src={pozaeu}
                alt='Andrei' 
                className='rounded-full w-40 h-40 mx-auto mb-6 object-cover'
            />
            <h3 className='text-3xl md:text-4xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-teal-400'>
                Andrei
            </h3>
            <p className='text-sm md:text-base lg:text-lg leading-relaxed md:leading-loose text-gray-300'>
                Sunt Andrei-Cătălin Moca și am avut oportunitatea extraordinară de a lucra ca Software Engineer Intern la Google în Zurich, Elveția. 
                În prezent, îmi continui studiile în Informatică la Universitatea Babeș-Bolyai, unde îmi dezvolt în continuare pasiunea pentru tehnologie.
                <br/><br/>
                Am absolvit liceul ca șef de promoție, cu un parcurs academic remarcabil. La examenul de Bacalaureat, am obținut nota maximă de 10/10 la Matematică, iar realizările mele de excepție mi-au oferit admiterea la toate universitățile din țară fără examene de intrare. La proba de Informatică a Bacalaureatului, am fost recompensat cu nota maximă de 10/10, confirmând astfel performanțele mele în domeniu.
                <br/><br/>
                Am reușit să acumulez o serie impresionantă de medalii și distincții, care reflectă pasiunea și dedicarea mea pentru competiții:
                <br/><br/>
                <ul className='list-disc list-inside text-left'>
                    <li>O medalie de aur și 4 medalii de argint la Olimpiada Națională de Informatică</li>
                    <li>2 medalii de bronz la Olimpiada Națională de Matematică</li>
                    <li>De 7 ori câștigător al premiului I la Olimpiada Județeană de Informatică</li>
                    <li>Medalie de bronz la o competiție internațională de informatică (infO(1) Cup)</li>
                    <li>Locul 4 la RCPC 2024, calificat la ICPC (International Collegiate Programming Contest) etapa Europa de Sud</li>
                    <li>... multe alte premii</li>
                </ul>
                <br/>
                Aceste performanțe au fost recunoscute prin obținerea a trei burse prestigioase, care mă plasează ca fiind cel mai premiat student al generației mele.
                <br/><br/>
                În plus față de activitatea competițională, am acumulat o experiență valoroasă de <span className='font-semibold'>2 ani în mentorat</span> în domeniul informaticii, unde am avut ocazia să ghidez și să inspir viitoarele generații!
            </p>
        </div>
    </div>

    {/* Section for Your Collaborator */}
    <div className='w-full max-w-xl mx-auto'>
        <div className='rounded-2xl shadow-xl p-4 md:p-8 text-center my-4 transition duration-500 ease-in-out transform hover:scale-105'>
            <img 
                src={pozasergiu} 
                alt='Sergiu' 
                className='rounded-full w-40 h-40 mx-auto mb-6 object-cover'
            />
            <h3 className='text-3xl md:text-4xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-teal-400'>
                Sergiu
            </h3>
            <p className='text-sm md:text-base lg:text-lg leading-relaxed md:leading-loose text-gray-300'>
                Sunt Sergiu Mocan, student la Informatică, secția engleză, la Universitatea Babeș-Bolyai din Cluj-Napoca. De mai bine de doi ani, îmi dedic timpul ajutând elevii să-și atingă potențialul maxim în informatică, oferind meditații atât pentru Bacalaureat și admiterea la facultate, cât și pentru îmbunătățirea performanțelor academice pe parcursul liceului.
                <br/><br/>
                Pe lângă activitatea de mentorat, de 6 luni sunt angajat ca software operator la o companie din România, unde îmi dezvolt constant abilitățile tehnice și experiența practică în domeniu. 
                <br/><br/>
                Misiunea mea este să împărtășesc cunoștințele acumulate și să ghidez elevii care se află la începutul drumului lor în tehnologie, oferindu-le o bază solidă și captivantă pentru viitorul lor în acest domeniu.
            </p>
        </div>
    </div>
    </div>
    </div>

    );
}

export default Portfolio;