import React from 'react';
import { AiFillFacebook } from 'react-icons/ai';

const Contact = () => {
  return (
    <div className='px-4 sm:px-6 lg:px-8 py-16 md:h-screen flex justify-center items-center'>
      <div className="w-full max-w-7xl bg-gradient-to-br from-purple-600 to-blue-600 rounded-xl md:rounded-full py-10 px-4 sm:px-6 lg:px-8 shadow-xl hover:shadow-2xl transition duration-500 ease-in-out">
        <div className="text-center">
          <h2 className="text-3xl sm:text-5xl font-bold text-white mb-6">
            Hai să învățăm împreună!
          </h2>
          <p className="text-md sm:text-2xl text-gray-100 mb-8">
            descoperă cursurile noastre educative și interactive
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-around items-center">
          {/* Facebook Link */}
          <div className="space-y-6 mb-6 md:mb-0">
            <a href="https://www.facebook.com/profile.php?id=61566522736338" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-4 hover:scale-110 transition-transform duration-300 ease-in-out">
              <AiFillFacebook className="w-14 h-14 text-blue-700 hover:text-white transition-colors duration-300" />
              <div>
                <h3 className="text-xl font-bold text-gray-200 hover:text-white transition-colors duration-300">Facebook</h3>
                <p className="text-sm text-gray-400 hover:text-gray-300 transition-colors duration-300">Info de Drag</p>
              </div>
            </a>
          </div>

          {/* Inscriere la cursuri */}
          <div className="w-full max-w-lg p-8 md:p-12 bg-black/50 rounded-xl backdrop-blur-md">
            <h2 className="text-2xl font-bold text-white mb-6">
              Înscrie-ți copilul la cursurile noastre creative!
            </h2>
            <p className="text-gray-100 text-md mb-8">
              Completează formularul și asigură-i copilului tău o experiență de învățare distractivă și educativă, dând click pe butonul de mai jos. În formular sunt precizate mai multe detalii despre desfășurare, cât și TARIFUL.
            </p>
            <a
              href="https://docs.google.com/forms/d/1cwVMI7kwBgS4RSFXHbYqfo_4zPzaurQW_0JS1MHjc9g/" 
              target="_blank"
              rel="noopener noreferrer"
              className="w-full inline-block bg-gradient-to-r from-yellow-400 to-pink-500 text-white font-bold px-6 py-4 rounded-md hover:bg-yellow-600 transition-all duration-300"
            >
              Formular pentru înscriere la cursuri (CLICK AICI)
            </a>
          </div>
        </div>

        {/* Contact Information */}
        <div className="mt-10 text-center text-gray-100">
          <p className="text-lg">
            Ne puteți contacta la numerele de telefon:
          </p>
          <p className="text-xl font-bold">
            0741 270 890 (WhatsApp) <br /> 0734 296 487 (WhatsApp)
          </p>
          <p className="mt-4">
            Sau pe pagina noastră de Facebook: <br />
            <a href="https://www.facebook.com/profile.php?id=61566522736338" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline">
              Info de Drag
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contact;
